export const HOMEPAGE_TABS = Object.freeze([
	'ALL_WIDGETS',
	'BANNER_HERO',
	'BANNER_HIGHLIGHT',
	'BANNER_SMALL',
	'BANNER_TEXT',
	'BANNER_PICTURE_THUMBNAIL',
]);

export const HOMEPAGE_COMPONENTS = Object.freeze({
	ALL_WIDGETS: {
		title: 'All widgets',
		component: 'WidgetList',
		routerTo: 'Homepage',
	},
	BANNER_HERO: {
		title: 'Hero banner',
		component: 'BannerGroupList',
		routerTo: 'BannerHeroList',
		routerToCreate: 'BannerHeroCreate',
		routerToEdit: 'BannerHeroEdit',
	},
	BANNER_HIGHLIGHT: {
		title: 'Highlight banner',
		component: 'BannerGroupList',
		routerTo: 'BannerHighlightList',
		routerToCreate: 'BannerHighlightCreate',
		routerToEdit: 'BannerHighlightEdit',
	},
	BANNER_SMALL: {
		title: 'Small banner',
		component: 'BannerGroupList',
		routerTo: 'BannerSmallList',
		routerToCreate: 'BannerSmallCreate',
		routerToEdit: 'BannerSmallEdit',
	},
	BANNER_TEXT: {
		title: 'Text banner',
		component: 'BannerGroupList',
		routerTo: 'BannerTextList',
		routerToCreate: 'BannerTextCreate',
		routerToEdit: 'BannerTextEdit',
	},
	BANNER_PICTURE_THUMBNAIL: {
		title: 'Picture Thumbnail banner',
		component: 'BannerGroupList',
		routerTo: 'BannerPictureThumbnailList',
		routerToCreate: 'BannerPictureThumbnailCreate',
		routerToEdit: 'BannerPictureThumbnailEdit',
	},
});
