<template>
	<form class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<BannerGeneral
			:id="id"
			ref="general"
			name-label="Text widget name*"
			:name="name"
			:priority="priority"
			:status="status"
			:widgets="widgets"
			:widget-type="widgetType"
			show-widget
			@update:name="name = $event"
			@update:priority="priority = $event"
			@update:status="status = $event"
			@update:widgets="widgets = $event"
		/>

		<BannerDate
			ref="date"
			:status="status"
			:start-date="startDate"
			:start-time="startTime"
			:end-date="endDate"
			:end-time="endTime"
			@update:startDate="startDate = $event"
			@update:startTime="startTime = $event"
			@update:endDate="endDate = $event"
			@update:endTime="endTime = $event"
		/>

		<!-- TODO: Update image for text widget -->
		<BannerContentWrapper
			:has-banner-link="false"
			desktop-img="/assets/images/text-widget-desktop.svg"
			mobile-img="/assets/images/text-widget-mobile.svg"
		>
			<template #title>
				Text widget
			</template>
			<template #detail>
				Banners will be displayed on the text widget as
				shown. Using the content depends on desktop, mobile, and language.
			</template>

			<CCol lg="12" class="mt-3">
				<label>Desktop Content (Thai)</label>
				<BaseRichTextEditor
					v-model="bannerDesktopContentTh"
					:is-valid="!$v.bannerDesktopContentTh.$error"
					:invalid-feedback="$t('global.error.required')"
					size="large"
				/>
			</CCol>

			<CCol lg="12" class="mt-3">
				<label>Desktop Content (English)</label>
				<BaseRichTextEditor
					v-model="bannerDesktopContentEn"
					:is-valid="!$v.bannerDesktopContentEn.$error"
					:invalid-feedback="$t('global.error.required')"
					size="large"
				/>
			</CCol>

			<CCol lg="12" class="mt-3">
				<label>Mobile Content (Thai)</label>
				<BaseRichTextEditor
					v-model="bannerMobileContentTh"
					:is-valid="!$v.bannerMobileContentTh.$error"
					:invalid-feedback="$t('global.error.required')"
					size="large"
				/>
			</CCol>

			<CCol lg="12" class="mt-3">
				<label>Mobile Content (English)</label>
				<BaseRichTextEditor
					v-model="bannerMobileContentEn"
					:is-valid="!$v.bannerMobileContentEn.$error"
					:invalid-feedback="$t('global.error.required')"
					size="large"
				/>
			</CCol>
		</BannerContentWrapper>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isLoading || isUpdating"
			:is-edit="isEditMode"
			:remove-text="isEditMode ? 'Remove banner' : null"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'BannerTextList'})"
			@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
		/>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteBanner.bind(null, id)"
			title="Remove this banner?"
			description="By removing this, all banner info will be disappeared from the list and linked widgets."
			@onSuccess="$router.push({ name: 'BannerTextList'})"
		/>
	</form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import BannerGeneral from '@/components/BannerGeneral.vue';
import BannerDate from '@/components/BannerDate.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';

import { BANNER_TYPE } from '../enums/banners';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'BannerTextForm',

	components: {
		BannerGeneral,
		BannerDate,
		BannerContentWrapper,
	},

	validations() {
		return {
			bannerDesktopContentTh: {
				required,
			},
			bannerDesktopContentEn: {
				required,
			},
			bannerMobileContentTh: {
				required,
			},
			bannerMobileContentEn: {
				required,
			},
		};
	},

	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: null,
		},
		bannerType: {
			type: String,
			default: BANNER_TYPE.text,
		},
	},

	data() {
		return {
			id: null,
			name: '',
			priority: 0,
			widgets: [],
			status: true,
			startDate: null,
			startTime: '00:00',
			endDate: null,
			endTime: '23:59',
			bannerDesktopContentTh: '',
			bannerDesktopContentEn: '',
			bannerMobileContentTh: '',
			bannerMobileContentEn: '',
		};
	},

	computed: {
		widgetType() {
			const widget = {
				hero: 'hero_banner',
				highlight: 'highlight_banner',
				small: 'small_banner',
				// In case text widget type and banner type are same
				text_widget: 'text_widget',
				picture_thumbnail: 'picture_thumbnail',
			};

			return widget[this.bannerType];
		},
	},

	watch: {
		data: {
			immediate: true,
			handler(value) {
				if (this.isEditMode && !this.isUpdating) {
					this.id = value.id;
					this.name = value.name;
					this.priority = value.priority;
					this.status = value.status;
					this.startDate = value.startDate;
					this.startTime = value.startTime;
					this.endDate = value.endDate;
					this.endTime = value.endTime;
					this.bannerDesktopContentTh = value.contents.desktopTh;
					this.bannerDesktopContentEn = value.contents.desktopEn;
					this.bannerMobileContentTh = value.contents.mobileTh;
					this.bannerMobileContentEn = value.contents.mobileEn;
					this.widgets = value.widgets;
				}
			},
		},
	},

	methods: {
		...mapActions({
			deleteBanner: 'banners/deleteBanner',
		}),

		handleChangeEndDate(value) {
			this.endDate = value;
		},

		async handleSubmit() {
			const generalRef = this.$refs.general.$v;
			const dateRef = this.$refs.date.$v;

			generalRef.$touch();
			dateRef.$touch();
			this.$v.$touch();

			if (
				!generalRef.$invalid &&
				!dateRef.$invalid &&
				!this.$v.$invalid
			) {
				const data = {
					name: this.name,
					type: BANNER_TYPE.text,
					priority: Number(this.priority, 10),
					is_visible: this.status,
					start_at: convertDateTimeToUTC(this.startDate, this.startTime),
					end_at: convertDateTimeToUTC(this.endDate, this.endTime),
					content_desktop_th: this.bannerDesktopContentTh,
					content_desktop_en: this.bannerDesktopContentEn,
					content_mobile_th: this.bannerMobileContentTh,
					content_mobile_en: this.bannerMobileContentEn,
					widget_ids: this.widgets.map((widget) => widget.id),
				};

				this.$emit('onSubmit', data);
			}
		},

		handleRemove() {
			this.$emit('onRemove');
		},
	},
};
</script>

<style lang="scss" scoped>
	.remove-banners {
		font-size: rem(14);
		color: $color-orange;

		align-items: center;

		cursor: pointer;
	}
</style>
